<template>
    <my-layout class="login_main">
        <template #body>
            <div class="box">
                <div class="body_header">
                    <img @click="$router.push('/h5')" class="close" src="../../assets/img/h5/close_icon.png"/>
                    <img class="logo" src="../../assets/img/h5/logo_h5_white.png"/>
                    <div class="sub">专业的竞价推广数据分析平台</div>
                    <div class="logo_tab" v-if="!is_new">
                        <span :class="loginType == 'fast'?'active':''" @click="changTab">短信登录/注册</span>
                        <span :class="loginType == 'psw'?'active':''" @click="changTab">密码登录</span>
                        <div class="angle" :class="loginType == 'fast'?'fast_position':'psw_position'"></div>
                    </div>
                    <div v-else class="logo_tab">
                        <span class="active">完善信息</span>
                        <div class="angle"></div>
                    </div>
                </div>
                <template v-if="!is_new">
                    <div v-if="loginType == 'fast'" class="fast_login">
                        <el-collapse-transition>
                            <div class="warning" v-show="warning1"><span>{{warning1}}</span></div>
                        </el-collapse-transition>
                        <div class="form">
                            <el-input v-model="phone" placeholder="请输入手机号码"></el-input>
                            <el-input v-model="code" class="code" placeholder="请输入验证码"></el-input>
                            <div class="button active" v-if="codeStatus" @click="getCode">获取验证码</div>
                            <div class="button disabled" v-else>{{ second }}秒后获取</div>
                            <div class="login login_active" v-if="phone && code && checked" @click="codeLogin">登录/注册</div>
                            <div class="login login_disabled" v-else>登录/注册</div>
                        </div>
                    </div>
                    <div v-else class="psw_login">
                        <el-collapse-transition>
                            <div class="warning" v-show="warning2"><span>{{warning2}}</span></div>
                        </el-collapse-transition>
                        <div class="form">
                            <el-input v-model="phone" placeholder="请输入手机号码"></el-input>
                            <el-input class="psw" v-model="passWord" placeholder="请输入密码" show-password></el-input>
                            <div class="login login_active" v-if="phone && passWord && checked" @click="pwdLogin">登录</div>
                            <div class="login login_disabled" v-else>登录</div>
                        </div>
                    </div>
                </template>
                <div v-else class="complemented">
                    <div class="subhead">
                        <img src="../../assets/img/login/success_icon.png" style="margin-right: 6px" height="16" width="16"/>
                        <span>注册成功！完善以下信息可以获得更好的体验</span>
                    </div>
                    <div class="title">一、自动推送监测动态</div>
                    <div class="ewm">
                        <div class="code_img">
                            <img :src="ewm_url"/>
                        </div>
                        <span class="text">长按保存图片，到微信扫一扫绑定，监测对象的动态将自动推送</span>
                    </div>
                    <div class="title">二、自动找出竞争对手 <span style="color: #ef3819;">*</span></div>
                    <div class="suggest_box">
                        <el-input class="suggest" v-model="company_name" type="text" @input="querySearchAsync" placeholder="请输入完整公司名，自动找出推广竞争对手">
                        </el-input>
                        <div v-show="suggestDialog && (suggestionList && suggestionList.length > 0)" v-loading="suggestLoading" class="suggest_list">
                            <div v-if="" class="suggest_item" v-for="(item, i) in suggestionList" :key="i" @click="selectCompany(item)">{{item.company_name}}</div>
                        </div>
                        <div class="login login_active" v-if="company_name" @click="submit">提交</div>
                        <div class="login login_disabled" v-else>提交</div>
                    </div>
                </div>
                <div v-if="!is_new" class="protocol">
                    <van-checkbox v-model="checked" shape="square" icon-size="12.5px" checked-color="rgba(32,127,247,0.7)">
                        <template #default>
                            <span class="text">我已阅读并接受</span>
                            <span class="link" @click.stop="$router.push('/protocol')">《AI竞投平台用户协议》</span>
                        </template>
                    </van-checkbox>
                    <div class="tip">未注册手机验证后自动为您创建账户</div>
                </div>

                <div class="footer">
                    <div>Copyright© 2021-2024 上海凭安网络科技有限公司 AI竞投（aijingtou.com）All Rights Reserved</div>
                    <div>沪ICP备12039960号-38</div>
                </div>
            </div>
        </template>
    </my-layout>
</template>

<script>
  import Yuan from "pingansec-vue-yuan";
  import { Button, Tab, Tabs, Toast, Field, Checkbox, Notify} from 'vant';
  import { pwd_login, sms_login, get_cloud_verify, send_sms, update_holder_name } from "@/api/login";
  import * as api from "@/api/search"
  import PinganAna from 'pingansec-vue-ana'
  import {get_wx_bind_qrcode} from "@/api/monitor";
  export default {
    name: "login",
    components: {
      [Button.name]:Button,
      [Tab.name]:Tab,
      [Tabs.name]:Tabs,
      [Toast.name]:Toast,
      [Field.name]:Field,
      [Checkbox.name]:Checkbox,
      [Notify .name]:Notify ,
    },
    data() {
      return {
        loginType: 'fast',
        codeStatus: true,
        second: 60,
        phone: '',
        code: '',
        passWord: '',
        checked: true,
        warning1: '',
        warning2: '',
        is_new: false,
        company_name: '',
        suggestDialog: false,
        suggestLoading: false,
        suggestionList: [],
        ewm_url: ''
      }
    },
    created() {
      PinganAna.fire(3040)
    },
    methods: {
      getWXEWM() {
        get_wx_bind_qrcode().then(res =>{
          if(res.data.result_code==0) {
            this.ewm_url = res.data.data.url
          }
        })
      },
      submit() {
        update_holder_name({holder_auth_name: this.company_name,}).then(res => {
          if(res.data.result_code == 0) {
            PinganAna.ready(() => {
              let source = '';
              if(localStorage.getItem('ajt_source')) {
                source = this.$C.sourceTurn(localStorage.getItem('ajt_source'))
              }
              PinganAna.userClickLog(
                '注册提交',
                '{"side":"h5","activity":"'+source+'"}',
                '【AI竞投】系统',
                3,
                '--',
                this.phone
              )
            })
            Toast.success({
              message: '提交成功,电脑端体验更佳,建议更换设备查看',
              duration: 3000,
              className: 'login_toast',
              onClose: () => {
                this.$router.push('/box/dataView');
              }
            });
          } else {
            Toast({
              type: 'fail',
              message: res.data.message
            })
          }
        })
      },
      selectCompany(item) {
        this.company_name = item.company_name;
        this.suggestDialog = false
      },
      querySearchAsync() {
        if(this.company_name) {
          this.suggestDialog = true
          this.suggestLoading = true
          let params = {
            keyword: this.company_name,
            type: 1
          };
          api.get_ad_compete_list_search(params).then(res => {
            if(res.data.result_code == 0) {
              this.suggestionList = []
              this.suggestionList = res.data.data
              this.suggestLoading = false
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        } else {
          this.suggestDialog = false
        }
      },
      changTab() {
        this.loginType == 'fast'?this.loginType = 'psw':this.loginType = 'fast'
      },
      getCode() {
        this.$C.telRegFn(this.phone).then((phone) => {
          if (!phone) {
            get_cloud_verify().then((res) => {
              let { result_code, message, data } = res.data;
              if (result_code == 0) {
                let token = data.token;
                let yuan = new Yuan({
                  author: '',
                  token,
                  completed: (img_code) => {
                    send_sms({
                      send_type: 1,
                      phone: this.phone,
                      img_token: token,
                      img_code,
                    }).then((sms) => {
                      let { result_code, message, data } = sms.data;
                      if (result_code == 0) {
                        this.countdown();
                      } else {
                        this.handleMsg(message)
                      }
                    });
                    yuan.hideCode();
                  },
                });
                yuan.showCode();
              } else {
                this.handleMsg(message)
              }
            });
          } else {
            this.handleMsg(phone)
          }
        });
      },
      // 60秒倒计时
      countdown() {
        this.second = 60;
        this.codeStatus = false;
        clearInterval(this.iTimer);
        this.iTimer = setInterval(() => {
          this.second--;
          if (this.second == 0) {
            clearInterval(this.iTimer);
            this.codeStatus = true;
          }
        }, 1000);
      },
      handleMsg(msg) {
        let that = this
        if(that.loginType == 'fast') {
          that.warning1 = msg;
          setTimeout(() =>{
            that.warning1 = ''
          },2000)
        } else {
          that.warning2 = msg;
          setTimeout(() =>{
            that.warning2 = ''
          },2000)
        }
      },
      handleLogin() {
        this.$C.getUserInfo().then(data => {
          PinganAna.ready(() => {
            PinganAna.userClickLog(
              '登录成功',
              '{"side":"h5"}',
              '【AI竞投】系统',
              data.vip_type == 5?3:data.vip_type == 10?1:2,
              '--',
              data.account
            )
          })
          PinganAna.fire(3042)
          PinganAna.fire(3092)
          Toast.success({
            message: '登录成功,电脑端体验更佳,建议更换设备查看',
            duration: 3000,
            className: 'login_toast',
            onClose: () => {
              this.$router.push('/box/dataView');
            }
          });
        })
      },
      codeLogin() {
        let params = {
          phone: this.phone,
          code: this.code,
        }
        sms_login(params).then((res) => {
          let { result_code, message, data } = res.data;
          if (result_code == 0 && data.token) {
            localStorage.setItem("ajt_token", data.token);
            if(data.select_roles) {
              this.$router.push('/changeSubject');
            } else {
              if(data.roles_info.isNew) {
                this.is_new = true
                this.getWXEWM()
              } else {
                this.handleLogin()
              }
            }
          } else {
            Toast.fail(message);
          }
        });
      },
      pwdLogin() {
        this.$C.telRegFn(this.phone).then((phone) => {
          if (!phone) {
            this.$C.psdRegFn(this.passWord).then((pwd) => {
              if (!pwd) {
                let params = {
                  phone: this.phone,
                  pwd: this.passWord
                }
                pwd_login(params).then((res) => {
                  let { result_code, message, data } = res.data;
                  if (result_code == 0 && data.token) {
                    localStorage.setItem("ajt_token", data.token);
                    this.handleLogin()
                  } else {
                    Toast.fail(message);
                  }
                });
              } else {
                this.handleMsg(pwd)
              }
            });
          } else {
            this.handleMsg(phone)
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">
.login_main {
    min-height: 2077px;
    .box {
        height: 100%;
        background-color: #fff;
        .tip {
            margin-top: 25px;
            font-size: 36px;
            color: #999999;
        }
        .body_header {
            width: 100%;
            height: 540px;
            background: url("../../assets/img/h5/h5_bg.png");
            background-size: cover;
            text-align: center;
            .close {
                position: absolute;
                width: 36px;
                height: 36px;
                top: 53px;
                right: 46px;
            }
            .logo {
                margin-top: 147px;
                margin-bottom: 50px;
                width: 374px;
                height: 107px;
            }
            .sub {
                font-size: 54px;
                color: #ffffff;
                opacity: 0.7;
            }
            .logo_tab {
                width: 90%;
                margin: 0 auto;
                margin-top: 95px;
                display: flex;
                position: relative;
                justify-content: space-around;
                font-size: 48px;
                color: #ffffff;
                span {
                    opacity: 0.7;
                }
                .active {
                    opacity: 1;
                }
            }
            .angle {
                position: absolute;
                top: 58px;
                display: inline-block;
                border-left: 22px solid transparent;
                border-right: 22px solid transparent;
                border-bottom: 25px solid #fff;
                transition: left 0.2s;
            }
            .fast_position {
                left: 250px;
            }
            .psw_position {
                left: 700px;
            }
        }
        .warning {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 34px;
            color: #ff3b3b;
            background-color: #ffe0e0;
        }
        .form {
            padding: 100px 40px 66px 40px;
            .code {
                width: calc(100% - 360px);
                margin-top: 60px;
            }
            .psw {
                margin-top: 60px;
            }
            .button {
                width: 340px;
                height: 130px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 42px;
                border: solid 2px #1f81f8;
                border-radius: 15px;
                margin-left: 20px;
                box-sizing: border-box;
            }
            .active {
                color: #1f81f8;
            }
            .disabled {
                color: #999999;
                border: solid 2px #f0f0f0;
            }
            .login {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 130px;
                font-size: 48px;
                border-radius: 15px;
                color: #ffffff;
                margin-top: 60px;
            }
            .login_disabled {
                background-color: rgba(31, 129, 248, 0.3);
            }
            .login_active {
                background-color: #1f81f8;
            }
        }
        .complemented {
            padding: 40px;
            .subhead {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 42px;
                color: #1f81f8;
                margin-top: 45px;
                margin-bottom: 70px;
            }
            .title {
                margin-left: 2px;
                font-size: 42px;
                color: #333333;
            }
            .suggest_box {
                position: relative;
                .suggest {
                    margin-top: 45px;
                }
                .suggest_list {
                    position: absolute;
                    top: 180px;
                    font-size: 42px;
                    width: calc(100% - 40px);
                    height: 400px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    background-color: #ffffff;
                    border-radius: 15px;
                    border: solid 2px #f0f0f0;
                    padding: 20px;
                    z-index: 20;
                    .suggest_item {
                        padding: 20px 30px;
                    }
                }
                .login {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 130px;
                    font-size: 48px;
                    border-radius: 15px;
                    color: #ffffff;
                    margin-top: 60px;
                }
                .login_disabled {
                    background-color: rgba(31, 129, 248, 0.3);
                }
                .login_active {
                    background-color: #1f81f8;
                }
            }
            .ewm {
                display: flex;
                flex-direction: column;
                align-items: center;
                .code_img {
                    background: url("../../assets/img/login/angle.png");
                    background-size: cover;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 353px;
                    height: 353px;
                    margin-top: 56px;
                    margin-bottom: 42px;
                    img {
                        width: 350px;
                        height: 350px;
                    }
                }
                .text {
                    font-size: 36px;
                    color: #999999;
                    margin-bottom: 84px;
                }
            }
        }
        .footer {
            width: 100%;
            position: absolute;
            bottom: 60px;
            text-align: center;
            line-height: 60px;
            font-size: 36px;
            color: #999999;
        }
    }
}
</style>
<style lang="scss">
.login_toast {
    width: 300px;
}
.login_main .box {
    .el-input__inner {
        height: 130px;
        background-color: #ffffff;
        border-radius: 15px;
        border: solid 2px #f0f0f0;
    }
    .protocol {
        padding: 0 40px;
        .text {
            font-size: 36px;
            color: #999999;
        }
        .link {
            font-size: 36px;
            color: #1f81f8;
        }
        .van-checkbox__icon .van-icon {
            line-height: unset;
            border: 2px solid rgba(32,127,247,0.7);
            border-radius: 5px;
        }
    }
}

</style>
